<template>
  <div>
    <h1>Bienvenido a su apartado para definir encuestas</h1>
    <b-form @reset="onReset" >
      <b-form-group id="input-group-1" label="Nombre de la encuesta:" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="encuesta.nombreEncuesta"
          placeholder="Ingresa el nombre de la encuesta"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Número de pregunta:" label-for="input-2">
        <b-form-input
          v-model="preguntaActual.no"
          placeholder="Ingrese su número de pregunta">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Pregunta actual:" label-for="input-3">
        <b-form-input
          v-model="preguntaActual.nombre"
          placeholder="Ingrese su pregunta empezando en mayuscula y encerrandola entre signos de interrogación">
        </b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Tipo de pregunta:" label-for="input-4">
        <b-form-select 
          v-model="preguntaActual.tipo" 
          :options="tipoPregunta" > 
        </b-form-select>
      </b-form-group>

      <div v-if="preguntaActual.tipo == 'Múltiple'">
      <b-form-input v-model="opcionPreguntaActual" placeholder="Inserte una opción para su pregunta"></b-form-input>
      <br>
      <b-button @click="preguntaActual.opciones.push(opcionPreguntaActual)" variant="primary">Agregar opcion</b-button>
      <h5 v-for="opcionP in preguntaActual.opciones" :key="opcionP">
        {{opcionP }}
      </h5>
    </div>

    <br>

  
    <b-button @click="agregarPreguntaActual()" variant="success">
      Guardar pregunta
    </b-button>




    <b-button type="reset" variant="danger">
      Borrar pregunta
    </b-button>

    <br>
    <h6>Resumen Pregunta</h6>
    {{preguntaActual}}
    <br>
    <h6>Resumen Encuesta</h6>
    {{encuesta}}

    <br>

    <b-button variant="dark" @click="enviarEncuesta" >
      Enviar encuesta
    </b-button>

    </b-form>

  </div>
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        encuesta: {
          idEncuesta:'',
          nombreEncuesta:'',
          preguntas:[
            {
              // no:null,
              // nombre:'',
              // tipo:'Múltiple',
              // opciones:[]
            }
          ]
        },

        opcionPreguntaActual:'',
        preguntaActual:{
              no:null,
              nombre:'',
              tipo:'Múltiple',
              opciones:[]
            },
        
        tipoPregunta:['Múltiple', 'Númerico', 'Si / No']
      }
    },
    methods: {
    
      onReset(event) {
        event.preventDefault()
        // Valores al Resetear encuesta

        this.preguntaActual.nombre=null
        this.preguntaActual.tipo=null
        this.preguntaActual.opciones=null
      },

      
      agregarPreguntaActual(){
        this.encuesta.preguntas.push(JSON.parse(JSON.stringify(this.preguntaActual)));
      },



      enviarEncuesta(){
          alert('Se envio la encuesta al servidor');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

    }
  }
</script>